@import "theme/child_theme_variables";

body.login {
    background-image: url('../img/walkway2-light.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}
.login {
    h1 a {
        background-image: url('../img/tf-logo-sm.png');
        height:51px;
        width:320px;
        background-size:100%;
    }
    .message {
        border-left:4px solid $primary;
    }
}
.wp-core-ui .button-primary {
    background:$primary;
    &:hover, &:focus {
        background:lighten($primary, 10%);
    }

}